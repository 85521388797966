<template>
  <div class="reserve">
    <div class="right">
      <div>
        <breadCrumb :siderContent="siderName" />
      </div>
      <div class="top">
        <a-input class="ipt" placeholder="请输入姓名/手机号/桌号" v-model="keywords" />
        <a-range-picker 
        class="myRange" 
        :placeholder="['开始时间', '结束时间']"
         @change="handleChange"
         v-model="time"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
        <a-select class="mySelect" placeholder="订单状态" @change="getKey"  v-model="status">
          <a-select-option   
            v-for="(item) in items"
            :key="item.key+'o'" 
            :value="item.key"
            >{{item.name}}
          </a-select-option>  
        </a-select>
        <a-button class="myButton" @click="search">查询</a-button>
        <a-button class="button" @click="reset">重置</a-button>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="members"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          bordered
          :pagination="{
            current:current,
            total:total,
            pageSize:10,
          }"
          @change="handleTableChange"
          :locale="{emptyText: '暂无数据'}"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {reserves} from '@/request/reserve.js';
const columns = [
  {
    title: "预定桌台",
    dataIndex: "table_name",
    ellipsis: true,
  },
  {
    title: "预订人",
    dataIndex: "name",
    ellipsis: true,
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    ellipsis: true,  
  },
  {
    title: "预定时间",
    dataIndex: "order_time",
    scopedSlots: { customRender: "way" },
  },
  {
    title: "就餐人数",
    dataIndex: "people",
    ellipsis: true,
  },
  {
    title: "预定状态",
    dataIndex: "status",
    ellipsis: true,
  },
];
export default {
  data() {
    return {
      columns,
      members: [],
      items: [
        { key: 1, name: "已确认" },
        { key: 2, name: "已完成" },
        { key: 3, name: "已取消" },
        { key: 4, name: "已过期" },
      ],
      siderName: "预定记录", //sider切换内容
      current:1,//当前页
      keywords:'',//姓名手机号桌号
      start_time:'',//开始时间
      end_time:'',//结束时间
      status:'预约状态',//预定状态
      total:0,//总数据条数
      time:[],//时间数组
      keywordsSure:'',//最终姓名手机号桌号
      start_timeSure:'',//最终开始时间
      end_timeSure:'',//最终结束时间
      statusSure:'预约状态',//最终预定状态

    };
  },
  components: {
    breadCrumb,
  },
  mounted(){
    this.keywords='',//姓名手机号桌号
    this.start_time='',//开始时间
    this.end_time='',//结束时间
     this.time=[]
    this.status='预约状态',//预定状态
    this.reserveRequest();
  },
  methods:{
    reserveRequest(){
       const _this=this;
       reserves({
        keywords:_this.keywordsSure,
        listRows:10,
        start_time:_this.start_timeSure,
        end_time:_this.end_timeSure,
        status:_this.statusSure,
        page:_this.current,
         })
        .then((res) => {
          if(res.data.status===200){
              _this.members=res.data.data.list.data && res.data.data.list.data;
              _this.total=res.data.data.list.total && res.data.data.list.total;
            }               
          })
        .catch(result => {
            return false;
      })
    },
    handleChange(value,dateString) { //获取时间
     if(dateString){
       this.time=dateString;
       this.start_time=dateString[0] && dateString[0];
       this.end_time=dateString[1] && dateString[1];
     }
    },
    getKey(value){//获取状态
      this.status=value;
    },
    search(){
       this.keywordsSure=this.keywords,//最终姓名手机号桌号
       this.start_timeSure=this.start_time,//最终开始时间
       this.end_timeSure=this.end_time,//最终结束时间
       this.statusSure=this.status,//最终预定状态
      this.current=1;
      this.reserveRequest()
    },
    reset(){
      this.keywordsSure='',//姓名手机号桌号
      this.start_timeSure='',//开始时间
      this.end_timeSure='',//结束时间
      this.statusSure='预约状态',//预定状态
      this.keywords='',//姓名手机号桌号
      this.start_time='',//开始时间
      this.end_time='',//结束时间
      this.status='预约状态',//预定状态
       this.time=[],
       this.current=1;
      this.reserveRequest()
    },
    handleTableChange(e){ //点击分页
       this.current = e.current;
       this.reserveRequest()
    },

  }
};
</script>

<style lang="less" scoped>
.reserve {
  width: 100%;
  height: 95.4vh;
  .right {
    width: 83vw;
    height: 95.4vh;
  }
}
/deep/.ant-table{
  margin: 0 2.5vh 0 2.5vh;
}
.top {
  display: flex;
  margin: 1.5vh;
  .ipt {
    width: 29vh;
    margin-left: 1vh;
    margin-right: 2.5vh;
    height:3.9vh;
  }
  .myRange {
    width: 37vh;
    margin-right: 2.5vh;
  }
  .mySelect {
    width: 19vh;
    margin-right: 2.5vh;
  }
  .myButton {
    height:3.9vh;
    width: 10vh;
    margin-right: 2.5vh;
    background-color: #ff6700;
    border: none;
    color: white;
    font-size: 1.8vh;
  }
  .button {
    width: 10vh;
    height:3.9vh;
    font-size: 1.8vh;
  }
  .myTable{
    margin: 2.5vh;
  }
}
/deep/.ant-pagination{
  margin-right:4vw;
}
/deep/td{
  padding:1.5vh !important;
  background: white !important;
  font-size: 1.8vh;
}  
/deep/th{
  background: rgba(248, 248, 255, 1) !important;
  font-size: 1.8vh;
}
/deep/.ant-calendar-picker-input{
   font-size: 1.8vh;
   height: 3.9vh;
}
/deep/.ant-select-selection__rendered{
 font-size: 1.8vh;
  height: 3.9vh;
  line-height: 3.9vh;
}
/deep/.ant-select-selection--single{
 height: 3.9vh;
  line-height: 3.9vh;
}
  
</style>