//开台详情页接口请求方法
import request from "@/request/request";


export function reserveInfo(data) {    //账单列表
  return request({
    url: "/clerk/table/list",
    method: "post",
    data: data,
  });
}
export function reserveList(data) {     //预订信息列表
  return request({
    url: "/clerk/table/reserveInfo",
    method: "post",
    data: data,
  });
}
export function cancelReserve(data) {    //取消预定
  return request({
    url: "/clerk/table/cancelReserve",
    method: "post",
    data: data,
  });
}
export function reserve(data) {    //新增预定
  return request({
    url: "/clerk/table/reserve",
    method: "post",
    data: data,
  });
}
export function reserves(data) {    //预定记录列表
  return request({
    url: "/clerk/table/reserveList",
    method: "post",
    data: data,
  });
}
export function finishReserve(data) {    //预定记录列表
  return request({
    url: "/clerk/table/finishReserve",
    method: "post",
    data: data,
  });
}





